import { Environment } from '@services/environment/environment.types'

export var environment: Environment = {
  production: false,
  appName: 'Mobila Doktorn - Patient',
  apiUrl: 'https://develop.pwa.mobiladoktorn.se/md-patient-api', // For local development
  //apiUrl: 'http://localhost:4203/md_patient_api_war_exploded',
  // Replace the apiUrl with the one below before pushing
  // apiUrl: 'https://pwa.mobiladoktorn.se/md-patient-api',
  uniqueIdentifier: 'MDP-DEV',
  userIdleTimeout: 120,
  language: 'swe',
  stripePublicKey: 'pk_test_51MyaXoLQv4J92Y2kLn9i7kFaqozTh8P2u7SQSaJ6qUvseAcwGrIEPGIzfTEZNDlRJskji0Sk8NwtcI8FuFrGLrSk007J2MHzRj',
     // 'pk_live_51Izcb5FQ3CE9QfD9FIMBxmXmzX9vhpquHDmGSYguoBgYp9QUI5MT53YVwxivzth59PX4El00xDVyJkOLgpuEJnhg00xL0vIx0J',
  
  contactDetails: {
    companyName: 'Docteum AB',
    companyNumber: '559115-4033',
    phone: '010-179 87 00',
    mobilDoktornPageUrl: 'https://www.mobiladoktorn.se',
    emailAddress: 'contact@mobiladoktorn.se',
    facebookUrl: 'https://www.facebook.com/MobilaDoktorn/',
    instagramUrl: 'https://www.instagram.com/mobiladoktorn/',
    twitterUrl: 'https://twitter.com/mobiladoktorn',
  },
}
// "target": "https://pwa.mobiladoktorn.se",
  