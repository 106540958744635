import { Observable, throwError } from 'rxjs'
import { Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { HttpMethod, TheError } from './http.types'
import { AuthLogoutService } from '@services/auth/auth-logout.service'
import { TranslateService } from '@ngx-translate/core'
import { ToastService } from '@services/toast/toast.service'
import { ApiErrorDetailsDto, ApiGeneralErrorEnum } from '@models/api-errors.types'
import * as _ from 'lodash'
import { Message } from 'primeng/api'
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog'
import { ServerUnavailableDialogComponent } from '@components/server-unavailable-dialog/server-unavailable-dialog.component'
import { SilentRouterService } from '@services/silent-router/silent-router.service'
import { tap } from 'rxjs/operators'

const LOGOUT_WHITELIST = ['logout', '1-phase', '2-phase']
const BUSINESS_ERROR_WHITELIST = ['BANK_ID', 'login_bankid']
const SERVER_UNAVAILABLE_STATUSES = [0, 502, 503, 504]
const UNHANDLED_BUSINESS_ERROR_DETAILS = 'Unhandled server error 400'

@Injectable({
  providedIn: 'root',
})
export class HttpErrorsService {
  private readonly errors = new Map<Symbol, any>()
  private serverUnavailableDialog?: DynamicDialogRef

  constructor(
    private readonly authLogout: AuthLogoutService,
    private readonly translate: TranslateService,
    private readonly toast: ToastService,
    private readonly dialog: DialogService,
    private readonly silentRouter: SilentRouterService,
  ) {}

  handleError(err: TheError, method: HttpMethod): Observable<never> {
    const id = Symbol('error')
    err.id = id

    this.errors.set(id, this.handler(err, method))

    return throwError(this.errors.get(id))
  }

  private handler(err: HttpErrorResponse, method: HttpMethod): HttpErrorResponse {
    const { status } = err
    const name = `status${status}`
    const callback: () => HttpErrorResponse = (this as any)[name]

    if (this.isServerUnavailable(status)) {
      if (!this.serverUnavailableDialog) {
        this.openServerUnavailableDialog(method)
      }

      return err
    }

    if (!status || !callback) {
      return this.default(err)
    }

    return (this as any)[name](err)
  }

  private isServerUnavailable(status: number): boolean {
    return SERVER_UNAVAILABLE_STATUSES.includes(status) || !navigator.onLine
  }

  private default(err: HttpErrorResponse): HttpErrorResponse {
    const message = this.translate.instant('apiErrors.generic')

    this.toast.error(message)

    return err
  }

  private openServerUnavailableDialog(method: HttpMethod) {
    this.serverUnavailableDialog = this.dialog.open(ServerUnavailableDialogComponent, {
      showHeader: false,
      closable: false,
      styleClass: 'no-network-dialog',
      transitionOptions: '0ms',
    })

    this.serverUnavailableDialog.onClose
      .pipe(
        tap(() => {
          if (method === 'GET') {
            this.silentRouter.reload()
          }
        }),
      )
      .subscribe(() => (this.serverUnavailableDialog = undefined))
  }

  protected status400(err: HttpErrorResponse): HttpErrorResponse {
    this.businessErrorHandler(err)

    return err
  }

  protected status401(err: HttpErrorResponse): HttpErrorResponse {
    const urlParts = err.url?.split('/')

    if (urlParts) {
      const basePath = urlParts[urlParts.length - 1]
      if (basePath === 'login') {
        return err
      }

      if (!LOGOUT_WHITELIST.includes(basePath)) {
        this.authLogout.submit()

        this.closeServerUnavailableDialog()
      }
    }

    return err
  }

  protected status404(err: HttpErrorResponse): HttpErrorResponse {
    const { error }: ApiErrorDetailsDto = err.error
    console.log('======ddd', error)
    const message = this.translate.instant('apiErrors.' + error)

    if (error !== 'RESOURCE_NOT_FOUND') {
      console.log('djdjd')
         this.toast.error(message)
    }
    // const message = this.translate.instant(error)


    return err
  }

  protected status409(err: HttpErrorResponse): HttpErrorResponse {
    this.businessErrorHandler(err)

    return err
  }

  private isBusinessError(errorDetails?: string): boolean {
    if (!errorDetails) {
      return false
    }

    return !BUSINESS_ERROR_WHITELIST.some(value => errorDetails.startsWith(value))
  }

  private businessErrorHandler(err: HttpErrorResponse): HttpErrorResponse {
    const { errorDetails }: ApiErrorDetailsDto = err.error

    if (errorDetails === UNHANDLED_BUSINESS_ERROR_DETAILS) {
      return this.default(err)
    }

    if (this.isBusinessError(errorDetails)) {
      const { summary, detail } = this.getErrorMessages(err.error)

      this.toast.error(summary!, {
        styleClass: 'toast-wrap',
        detail,
      })
    }

    return err
  }

  private getErrorMessages({ error, errorDetails }: ApiErrorDetailsDto): Message {
    console.log('===<<<<>>>', errorDetails)
    if (error !== ApiGeneralErrorEnum.VALIDATION_ERROR || 'RESOURCE_NOT_FOUND') {
      return {
        summary: this.translate.instant('apiErrors.' + errorDetails ?? error),
        // summary: this.translate.instant(errorDetails ?? error),
      }
    }

    return {
      summary: this.translate.instant('apiErrors.VALIDATION_ERROR'),
      detail: this.parseValidationErrorDetails(errorDetails!),
    }
  }

  private parseValidationErrorDetails(errorDetails: string): string {
    const fields = errorDetails.split(' ')

    return fields
      .map(item => {
        const [fieldKey, errorKey] = item.split(':')
        const field = this.translate.instant('general.' + _.camelCase(fieldKey))
        return this.translate.instant('validationErrors.' + _.camelCase(errorKey), { field })
      })
      .join('\n')
  }

  private closeServerUnavailableDialog(): void {
    this.serverUnavailableDialog?.close()
  }
}
